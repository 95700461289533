import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useVisitorStore = create<{
    id: string | undefined,
    setId: (id: string) => void
    cookieConsent: boolean | undefined,
    setCookieConsent: (accepted: boolean) => void
}>()(
    persist(
        (set) => ({
            id: undefined,
            setId: (id: string) => set((state) => ({ ...state, id: id })),
            cookieConsent: undefined,
            setCookieConsent: (accepted: boolean) => set((state) => ({ ...state, cookieConsent: accepted })),
        }),
        {
            name: 'visitor-storage',
        }
    )
)
