"use client"

import { addVisit } from "@/lib/actions/analytics"
import { useVisitorStore } from "@/lib/stores/visitorStore"
import { usePathname } from "next/navigation"
import { useEffect, useTransition } from "react"

export default function Analytics() {
    const visitorStore = useVisitorStore()
    const pathname = usePathname()
    const [, startTransition] = useTransition()


    useEffect(() => {
        if ((visitorStore.cookieConsent !== true) || !pathname) {
            return
        }

        startTransition(async () => {
            const id = await addVisit(pathname, visitorStore.id)
            if (!!id) {
                visitorStore.setId(id)
            }
        })
    }, [visitorStore.cookieConsent, pathname])

    return null
}
